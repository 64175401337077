import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import { createRoot } from 'react-dom/client'
import Disconnections from './pages/Disconnections';
import Privacy from './pages/Privacy';

export default function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Disconnections />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    );
  }

createRoot(document.getElementById('root')).render(<App />)