function Privacy() {
    return (
        <div class='container'>
            <p/>
            <div className='shadow-lg p-3 mb-5 rounded'>
                <h3>Privacy</h3>
                <p/>
                <h6>I do not collect any data other than what you fill in the application yourself</h6>
                <h6>This data is not sent anywhere</h6>
                <h6>Does not contain ads</h6>
            </div>
        </div>
    );
  }
  
  export default Privacy;