import React from 'react';
import Header from '../components/Header';
import Actual from '../components/Actual';

function Disconnections() {
  return (
      <div>
          <p />
          <Header />
          <div className='container'>
            <Actual />
          </div>
      </div>
  );
}

export default Disconnections;
