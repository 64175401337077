import React, { useEffect, useState } from 'react';

const Actual = (props) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      const url = "https://api.disconnections.net/disconnections/select_all";
      //const url = "http://localhost:81/disconnections/select_all";
      //const url = "http://backend:81/disconnections/select_all";

      fetch(url, {
        method: "POST",
        headers: {
          "Authorization": "Bearer b51b10b7-9cc4-4292-ae0b-3fbe717833b8"
      }
      })
        .then(res => res.json())
        .then(
          (result) => {
            setData(result);
          },
          (error) => {
            setError(error);
          }
        )
    }, [])
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }  else {
      return (
        <div>
          {
          data.map((byDate, index) => (
            <div key={Math.random()}>
              {
                index === 0 
                ? 
                  <div>
                    <div className='d-flex justify-content-center'>
                      <h4>{byDate.date}</h4>
                    </div>
                    <p/>
                  </div>
                  
                :
                  <div>
                  <div className='d-flex justify-content-center'>
                    <h4>{byDate.date}</h4>
                  </div>
                  <p/>
                </div>
              }
              {
                byDate.areas.map(byArea => (
                  <div key={Math.random()} className='shadow-lg p-3 mb-5 rounded'>
                  <h4>{byArea.area}</h4>
                  <p/>
                  <div className='table-responsive'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Utility</th>
                        <th>Street</th>
                        <th>Number</th>
                        <th>Date</th>
                        <th>Cause</th>
                      </tr>
                    </thead>
                    <tbody>
                  {
                    byArea.disconnections.map(disconnection => (
                    <tr key={Math.random()}>
                      <td>{disconnection.utility}</td>
                      <td>{disconnection.streetOriginal}</td>
                      <td>{disconnection.houseNumber}</td>
                      <td>{disconnection.displayDate}</td>
                      <td>{disconnection.cause}</td>
                    </tr>
                    ))
                  }
                    </tbody>
                  </table>
                  </div>
                  </div>
                )
                )
              }
            </div>
          ))
          }
        </div>
      );
    }
};

export default Actual;